<template>
  <div
    class="pure-u-1 pure-g nav fixed-header"
    :class="{'logged-out' : !loggedIn}"
  >
    <client-only>
      <ImportantMessages
        v-if="importantMessages"
        :important-messages="importantMessages"
        class="head__notification"
        v-show="!showRegionSwitch && !isScrolled"
      />
      <div
        class="nav__waiting__indicator"
        v-if="!isReady"
      >
        <div
          class="skeleton"
        >
          <Skeleton class="skeleton__text" />
          <Skeleton class="skeleton__arrow" />
        </div>
      </div>
    </client-only>
    <header
      class="pure-u-1 pure-g nav"
      :class="{'hide-meta-menu': !loggedIn || availableCompanies <= 0 }"
    >
      <MetaHeader
        :is-ready="isReady && !companiesLoading"
        @reload-translations="handleReloadTranslations"
        @bell-clicked="notificationOverlay = !notificationOverlay"
        @login="login"
        @change-customer="handleCustomerChange"
      />
      <HeaderLoggedInSkeleton v-show="(!isReady && loggedIn) || companiesLoading" />
      <HeaderLoggedOutSkeleton v-show="!isReady && !loggedIn" />
      <div class="mobile-nav">
        <div
          class="nav-header-wrapper"
        >
          <div class="nav-header">
            <div
              class="nav-close-button"
              v-if="isOpenedMobile"
              @click="toggleNavigation(false, 'mobile')"
            >
              <NavigationClose class="mobile-icon" />
            </div>
            <div
              class="nav-menu-button"
              v-if="!isOpenedMobile"
              @click="toggleNavigation(true, 'mobile')"
            >
              <NavigationMenu class="mobile-icon" />
            </div>
            <div class="nav-header-img">
              <nuxt-link
                :to="localePath('/')"
                @click="toggleNavigation(false, 'mobile')"
              >
                <img
                  :src="BASE_URL + '/img/logo_fst.svg'"
                  :alt="$tv('Logo')"
                >
              </nuxt-link>
            </div>
            <div class="nav-header-icons">
              <client-only>
                <NotificationBell
                  @bell-clicked="notificationOverlay = !notificationOverlay"
                  :unread-notifications-length="unreadNotifications.length"
                  v-if="loggedIn"
                />
                <MountedTeleport
                  :to="'.modal-container'"
                >
                  <PopupBase
                    class="desktop-move-notifications"
                    :show="notificationOverlay"
                    @close-pop-up="notificationOverlay = false"
                  >
                    <div
                      class="h3"
                    >
                      {{ $tv('notifications', 'notifications') }}
                    </div>
                    <NotificationList
                      :notifications="unreadNotifications"
                      :notifications-hidden="notificationsHidden"
                    />
                  </PopupBase>
                </MountedTeleport>
                <nuxt-link
                  :to="localePath('/profile')"
                  @click="toggleNavigation(false, 'mobile')"
                  v-if="loggedIn"
                >
                  <SocialPerson class="mobile-icon user" />
                </nuxt-link>
                <a
                  v-if="!loggedIn"
                  href="#"
                  @click.prevent="login"
                >
                  <SocialPerson class="mobile-icon user" />
                </a>
                <nuxt-link
                  :to="localePath('/cart')"
                  v-if="loggedIn && hasCartViewPermission"
                  class="cart-mobile-icon"
                >
                  <ActionShoppingCart class="mobile-icon" />
                  <span
                    class="count"
                    v-text="count"
                  />
                </nuxt-link>
              </client-only>
              <a
                @click.prevent="openSearch()"
                href="#"
              ><ActionSearch class="mobile-icon search" /></a>
              <MountedTeleport :to="'.modal-container'">
                <PopupBase
                  :show="showSearchModal"
                  @close-pop-up="showSearchModal = false"
                >
                  <SearchOverlayMobile
                    v-if="!newSearch"
                    class="mobileModal"
                    :focus="searchFocussed"
                    @search-blur="searchFocussed = false"
                    :search-term-result="searchTermResult"
                    :l2categories="primaryl2Categories"
                    :categories="l2Categories"
                    :search-term="searchTerm"
                    :is-inch-search="isInchSearch"
                    :is-any-search="isAnySearch"
                    @close-overlay="showSearchModal = false"
                    @search-term="getOverlay"
                    :is-loading="isLoading"
                    @openBuyBox="openBuyBox"
                    @changeUnit="changeUnit"
                    @clickItem="clickItem"
                  />
                  <NewSearchOverlayMobile
                    v-else
                    class="mobileModal"
                    :focus="searchFocussed"
                    @search-blur="searchFocussed = false"
                    :search-term-result="searchTermResult"
                    :l2categories="primaryl2Categories"
                    :categories="l2Categories"
                    :search-term="searchTerm"
                    :is-inch-search="isInchSearch"
                    :is-any-search="isAnySearch"
                    @close-overlay="showSearchModal = false"
                    @search-term="getOverlay"
                    :is-loading="isLoading"
                    @openBuyBox="openBuyBox"
                    @openBuyBoxSku="openBuyBoxSku($event)"
                    @changeUnit="changeUnit"
                    @clickItem="clickItem"
                  />
                </PopupBase>
              </MountedTeleport>
            </div>
          </div>
        </div>
        <div
          class="mobile-menu-wrapper"
          :class="{'logged-out': !$auth.loggedIn}"
        >
          <MobileMenu
            :menu-points="mobileMenuSections"
            :open="isOpenedMobile"
            @reload-translations="handleReloadTranslations"
            @close-menu="toggleNavigation(false, 'mobile')"
          />
        </div>
      </div>
      <div
        class="pure-u-1 nav__main"
        :class="{'scrolled': isScrolled, 'mega-menu__open': menuOpen}"
      >
        <div class="header-row header-row-container">
          <div class="nav__main__container nav__main__logo">
            <nuxt-link
              :to="nuxtApp.$globalization.localePath('/', $i18n.locale, $globalization.__currentRegion)"
            >
              <img
                :src="BASE_URL + '/img/logo_fst.svg'"
                alt="Freudenberg"
              >
            </nuxt-link>
          </div>
          <div class="nav__main__container nav__main__search pure-form pure-u-1-2">
            <ToolTip
              v-if="showCategoryToolTip"
              :show-tool-tip="showCategoryToolTip"
              tool-tip-position="bottom"
              arrow-position-vertically="top"
              arrow-position-horizontally="left"
              :hide-arrow="true"
              :id="'category-tooltip'"
              v-click-outside="handleCategoryTooltip"
            >
              <TreeView
                :data-items="treeDataItems"
                :expand-icons="true"
                @expandchange="onExpandChange"
                @itemclick="onItemClick"
              />
            </ToolTip>
            <SearchInputField
              :selected-category="selectedCategory"
              @search-triggered="toggleNavigation(false)"
              @searchTerm="getOverlay"
              @openOverlay="newSearch ? showOverlay = true : showOverlay = !showOverlay"
              @close-overlay="showOverlay = false"
              :is-loading="isLoading"
              :is-inch-search="isInchSearch"
              :direct-link="onlyResultDirectLink"
              :overlay-visible="showOverlay"
              @changeUnit="changeUnit"
              @open-category-tooltip="handleCategoryTooltip"
            />
          </div>
          <div
            class="nav__main__container nav__main__overlay"
          >
            <SearchOverlayDesktop
              v-if="showOverlay && !newSearch"
              :search-term-result="searchTermResult"
              :l2categories="primaryl2Categories"
              :categories="l2Categories"
              :search-term="searchTerm"
              :search-filter="selectedCategory.searchFilter"
              :is-inch-search="isInchSearch"
              :is-any-search="isAnySearch"
              @close-overlay="showOverlay = !showOverlay"
              @openBuyBox="openBuyBox"
              @openBuyBoxSku="openBuyBoxSku($event)"
              @clickItem="clickItem"
            />
            <NewSearchOverlayDesktop
              v-show="showOverlay && newSearch"
              :active="newSearch"
              :search-term-result="searchTermResult"
              :l2categories="primaryl2Categories"
              :categories="l2Categories"
              :search-term="searchTerm"
              :search-filter="selectedCategory.searchFilter"
              :is-inch-search="isInchSearch"
              :is-any-search="isAnySearch"
              @close-overlay="showOverlay = false"
              @openBuyBox="openBuyBox"
              @openBuyBoxSku="openBuyBoxSku($event)"
              @clickItem="clickItem"
            />
          </div>
          <div class="nav__main__container nav__main__logos">
            <div v-show="$auth && $auth.loggedIn">
              <img
                :src="BASE_URL + '/img/logo_sealing_tech_with_easy_eservices.svg'"
                :alt="$tv('sealingTechnologies','nagivation')"
                class="svg__w-easy"
              >
            </div>
            <div v-show="!$auth || !$auth.loggedIn">
              <img
                :src="BASE_URL + '/img/logo-innovating-together.svg'"
                :alt="$tv('sealingTechnologies','nagivation')"
                class="svg__w-easy"
              >
            </div>
          </div>
          <client-only>
            <div
              class="nav__main__container nav__main__business-unit nav__main__shop"
              v-show="$auth && $auth.loggedIn"
            >
              <div>
                <nuxt-link
                  :to="localePath('/cart')"
                  v-if="hasCartViewPermission"
                  :title="$tv('services.shoppingCartIconTooltip', 'header')"
                  class="nav__main__shop__cart__link"
                >
                  <div
                    class="nav__main__shop__icon"
                  >
                    <span
                      v-if="count || count === 0"
                      class="nav__main__shop__icon__bubble"
                      :class="{pulse: pulseCart}"
                      v-text="count"
                    />
                    <span
                      v-else
                      class="nav__main__shop__icon__bubble k-i-loading loading-indicator loading-indicator--small"
                    />
                    <ActionShoppingCart
                      :title="$tv('services.shoppingCartIconTooltip', 'header')"
                      class="mobile-icon cart"
                    />
                  </div>
                </nuxt-link>
              </div>
            </div>
          </client-only>
        </div>
        <div
          class="header-row"
          :class="{'scrolled': isScrolled}"
        >
          <div class="nav__main__container nav__main__categories">
            <a
              href="#"
              class="hover-effect__underlined"
              :class="{'mega-menu_cat': menuCatOpenHover}"
              @mouseover="toggleNavigation(true, 'catHover')"
              @mouseleave="toggleNavigation(false, 'catHover')"
            >
              <span>{{ $tv('products', 'menu') }}</span>
              <span class="k-icon k-i-caret-alt-down k-button-icon k-i-arrow-chevron-down" />
            </a>
            <a
              href="#"
              class="hover-effect__underlined"
              :class="{'mega-menu_sel': menuSelectorsOpenHover}"
              @mouseover="toggleNavigation(true, 'selHover')"
              @mouseleave="toggleNavigation(false, 'selHover')"
            >
              <span>{{ $tv('selectors', 'menu') }}</span>
              <span class="k-icon k-i-caret-alt-down k-button-icon k-i-arrow-chevron-down" />
            </a>
          </div>
          <div class="nav__main__service_menu">
            <div
              v-for="(service, i) in services"
              :key="'service'+i"
            >
              <a
                v-if="!service.localRoute"
                class="hover-effect__underlined"
                :href="service.externalUrl"
                :target="service.externalUrl ? '_blank' : '_self'"
              >{{ service.label }}</a>
              <nuxt-link
                v-else
                class="hover-effect__underlined"
                :to="localePath(service.localRoute)"
              >
                {{ service.label }}
              </nuxt-link>
            </div>
          </div>
        </div>
      </div>
      <div
        v-show="menuOpen"
        class="megamenu"
        @mouseover="clearTimeout(this.resetHover)"
        @mouseleave="toggleNavigation(false)"
      >
        <MegaMenu
          :sections="menuSections"
          @close-menu="toggleNavigation(false)"
        />
      </div>
      <PageNavigation
        v-show="!isScrolled && !menuOpen"
        :list-links="listLinks"
      />
      <client-only>
        <MountedTeleport :to="'.modal-container'">
          <PopupBase
            :show="openCartModal"
            @close-pop-up="openCartModal = false"
            :css-class="buyboxClass"
            :close-on-outside-click="true"
          >
            <ProductBuyBoxPopup
              v-if="(!$auth.loggedIn || ($auth.loggedIn && isDefaultCustomer)) && product"
              :is-default-customer="isDefaultCustomer"
              :product="product"
            />
            <ProductBuyBox
              v-if="$auth.loggedIn && !isDefaultCustomer && openCartModal"
              :product-data="product"
              class="buy-box-in-popup"
              @productSuccessfullyAdded="onProductSuccessfullyAdded"
              @reloadProduct="reloadProduct"
              @close-pop-up="openCartModal = false"
            />
          </PopupBase>
        </MountedTeleport>
      </client-only>
    </header>
  </div>
</template>

<script>
import MetaHeader from '~/components/header/MetaHeader'
import PopupBase from '~/components/popup/PopupBase.vue'
import SearchOverlayMobile from '~/components/search/SearchOverlayMobile.vue'
import NewSearchOverlayMobile from '~/components/search/NewSearchOverlayMobile.vue'
import SearchInputField from '../search/SearchInputField'
import PageNavigation from './PageNavigation'
import {storeToRefs} from 'pinia'
import NotificationBell from '~/components/notifications/NotificationBell.vue'
import NotificationList from '~/components/notifications/NotificationList.vue'
import SearchOverlayDesktop from '~/components/search/SearchOverlayDesktop.vue'
import NewSearchOverlayDesktop from '~/components/search/NewSearchOverlayDesktop.vue'
import {useProductService} from '@/composables/product_service'
import {useCustomerService} from '@/composables/customer_service'
import ProductBuyBox from '~/components/product-detail/ProductBuyBox'
import {SEARCH, USER_RIGHTS_MAPPING, NON_ATTRIBUTE_FILTERS, NON_ATTRIBUTE_FILTERS_NEW, PROPERTY_SCOPE_NAMES, CUSTOMER_SETTING_KEYS, REGION_KEYS, CONFIGURATION_KEYS, CATEGORIES} from '~/assets/js/constants'
import NavigationClose from '~/assets/icons/navigation_close.svg?component'
import NavigationMenu from '~/assets/icons/navigation_menu.svg?component'
import SocialPerson from '~/assets/icons/social_person.svg?component'
import ActionShoppingCart from '~/assets/icons/action_shopping_cart.svg?component'
import ActionSearch from '~/assets/icons/action_search.svg?component'
import defaultCustomerMixin from '~/mixins/default_customer'
import {INCH_FILTER, SHOW_INCH_VALUES_FILTER} from '~/assets/js/filters'
import reloadProductMixin from '~/mixins/reload_product'
import {useCategoryDesignService} from '@/composables/category_design_service'
import {Skeleton} from '@progress/kendo-vue-indicators'
import {TreeView, processTreeViewItems} from '@progress/kendo-vue-treeview'
import {defaultCategoriesComparer} from '~/assets/js/utils/category_comparer.js'
import {useUserStore} from '@/store/user'
import {useNotificationStore} from '@/store/notification'
import {useCartStore} from '@/store/cart'
import {useCustomerSettingsStore} from '@/store/customer-settings'
import {useProductDataStore} from '@/store/product-data'
import MountedTeleport from '~/components/MountedTeleport'
import {getUrlFromTechnicalName} from '~/mixins/category_urls'
import ProductBuyBoxPopup from '~/components/product-detail/ProductBuyBoxPopup'
import ImportantMessages from '~/components/header/ImportantMessages'
import {useConfigurationDataStore} from '@/store/configuration-data'
import HeaderLoggedInSkeleton from '../skeletons/HeaderLoggedInSkeleton.vue'
import HeaderLoggedOutSkeleton from '../skeletons/HeaderLoggedOutSkeleton.vue'
import MegaMenu from '~/components/header/MegaMenu.vue'
import MobileMenu from '~/components/navigation/MobileMenu.vue'

export default defineComponent({
  mixins: [defaultCustomerMixin, reloadProductMixin],
  components: {
    ProductBuyBoxPopup,
    ProductBuyBox,
    MetaHeader,
    PageNavigation,
    SearchOverlayMobile,
    PopupBase,
    SearchInputField,
    NotificationList,
    NotificationBell,
    SearchOverlayDesktop,
    NewSearchOverlayDesktop,
    NavigationClose,
    NavigationMenu,
    SocialPerson,
    ActionShoppingCart,
    ActionSearch,
    Skeleton,
    TreeView,
    MountedTeleport,
    ImportantMessages,
    HeaderLoggedInSkeleton,
    HeaderLoggedOutSkeleton,
    MegaMenu,
    MobileMenu,
    NewSearchOverlayMobile
  },
  async setup() {
    const {getFilteredCustomerList} = useCustomerService()
    const {getCategoriesByTechnicalNames} = useCategoryDesignService()

    const {settings, loggedIn, loadedCustomerUuid, showInchValues, excludedBrands, name, email} = storeToRefs(useUserStore())
    const {unreadNotifications} = storeToRefs(useNotificationStore())
    const {cartCountComputed} = storeToRefs(useCartStore())
    const {currentCustomerSettings} = storeToRefs(useCustomerSettingsStore())
    const {categories} = storeToRefs(useProductDataStore())

    const {updateSettings, setAvailableCompanies, setCompanies, setShowInchValues} = useUserStore()
    const {availableCompanies, companiesLoading} = storeToRefs(useUserStore())

    const {getProductsBySearchTerm, getProductsBySearchTermNew, getCustomerSuggestions, getProductBySku, getProductBySkuNew} = useProductService()
    const {newSearch, configurations} = storeToRefs(useConfigurationDataStore())

    const {setCategories} = useProductDataStore()
    const {getCategories} = useCategoryDesignService()
    const nuxtApp = useNuxtApp()
    let categoriesContent = nuxtApp.payload?.data[`categories_null_0_-1_sortIndex_false_[{"field":"IsProductDesign","operator":"ne","value":"true"},{"field":"regions.RegionShortName","operator":"eq","value":"${nuxtApp.$globalization.getRegion()}"}]_false`]
    if (!categoriesContent) {
      categoriesContent = await getCategories()
    }
    await setCategories(categoriesContent)

    return {
      getFilteredCustomerList,
      getCategoriesByTechnicalNames,
      userSettings: settings,
      loggedIn,
      currentCustomerUuid: loadedCustomerUuid,
      showInchValues,
      unreadNotifications,
      count: cartCountComputed,
      currentCustomerSettings,
      categories,
      updateSettings,
      setAvailableCompanies,
      setCompanies,
      setShowInchValues,
      getProductsBySearchTerm,
      getProductsBySearchTermNew,
      getProductBySku,
      getProductBySkuNew,
      getCustomerSuggestions,
      BASE_URL: useRuntimeConfig().public.BASE_URL,
      newSearch,
      availableCompaniesStore: availableCompanies,
      companiesLoading,
      excludedBrands,
      userEmail: email,
      userName: name,
      configurations,
      nuxtApp
    }
  },
  data() {
    return {
      searchTerm: '',
      showSearchModal: false,
      notificationOverlay: false,
      searchFocussed: false,
      notificationsHidden: {},
      searchTermResult: {},
      primaryl2Categories: [],
      l2Categories: [],
      showOverlay: false,
      isLoading: false,
      openCartModal: false,
      product: null,
      pulseCart: false,
      isReady: false,
      searchTime: null,
      searchDebouncer: null,
      selectedCategory: {text: this.$tv('all', 'search'), id: 'all'},
      showCategoryToolTip: false,
      check: [],
      expand: {
        ids: ['Item2'],
        idField: 'text'
      },
      select: [''],
      tooltipTimer: null,
      isScrolled: false,
      lastTop: 0,
      menuCatOpenHover: false,
      menuSelectorsOpenHover: false,
      resetHover: null,
      isAnySearch: false,
      socialIntentsLoadedOnce: false,
      isOpenedMobile: false
    }
  },
  props: {
    importantMessages: {
      type: Array,
      default() {
        return []
      }
    }
  },
  fetchOnServer: false,
  async mounted() {
    this.isReady = true
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      this.isScrolled = window.scrollY > this.lastTop && !this.menuOpen
      this.lastTop = window.scrollY
    },
    handleReloadTranslations() {
      this.$emit('reload-translations')
    },
    handleCustomerChange() {
      this.$emit('change-customer')
    },
    clearTimeout(timeout) {
      clearTimeout(timeout)
    },
    toggleNavigation(state, type = null) {
      if (type === 'catHover') {
        if (state) {
          this.menuCatOpenHover = state === null ? !this.menuCatOpenHover : state
          clearTimeout(this.resetHover)
        } else {
          this.resetHover = setTimeout(() => {this.menuCatOpenHover = state === null ? !this.menuCatOpenHover : state}, 200)
        }
        this.menuSelectorsOpenHover = state === null ? false : false
        this.isOpenedMobile = state === null ? false : false
      } else if (type === 'selHover') {
        if (state) {
          this.menuSelectorsOpenHover = state === null ? !this.menuSelectorsOpenHover : state
          clearTimeout(this.resetHover)
        } else {
          this.resetHover = setTimeout(() => {this.menuSelectorsOpenHover = state === null ? !this.menuSelectorsOpenHover : state}, 200)
        }
        this.menuCatOpenHover = state === null ? false : false
        this.isOpenedMobile = state === null ? false : false
      } else if (type === 'mobile') {
        this.isOpenedMobile = state === null ? !this.isOpenedMobile : state
        this.menuCatOpenHover = state === null ? false : false
        this.menuSelectorsOpenHover = state === null ? false : false
      } else {
        this.menuCatOpenHover = state === null ? false : false
        this.menuSelectorsOpenHover = state === null ? false : false
        this.isOpenedMobile = state === null ? false : false
      }
    },
    openSearch() {
      this.showSearchModal = true
      this.searchFocussed = true
    },
    onItemClick(event) {
      this.select = [event.itemHierarchicalIndex]
      this.selectedCategory = event.item
      this.showCategoryToolTip = false
      if (this.searchTerm) {
        this.getOverlay(this.searchTerm)
      }
    },
    onExpandChange(event) {
      let ids = this.expand.ids.slice()
      const index = ids.indexOf(event.item.text)
      index === -1 ? ids.push(event.item.text) : ids.splice(index, 1)
      this.expand = {
        ids,
        idField: 'text'
      }
    },
    async getOverlay(value) {
      if (this.searchDebouncer) {
        clearTimeout(this.searchDebouncer)
      }

      this.searchDebouncer = setTimeout(async () => {
        this.searchTermResult = {}
        this.primaryl2Categories = []
        this.isLoading = true
        if (value.length >= 1) {
          this.searchTerm = value
          this.showOverlay = true
          // use pagination options to get only the first X items from API
          const options = {
            pagination: {
              take: this.$config.public.topProductResults,
              skip: 0
            },
            sort: []
          }
          let time = Date.now()
          this.searchTime = time

          let designFilter = null
          if (this.selectedCategory.id !== 'all') {
            designFilter = this.selectedCategory.id
          }
          let res = null
          if (this.newSearch) {
            let customerRes = this.currentCustomerUuid !== 'default' ? await this.getCustomerSuggestions(this.searchTerm) : null
            res = await this.getProductsBySearchTermNew(this.searchTerm, [NON_ATTRIBUTE_FILTERS_NEW.PRIMARYL2CATEGORY], this.$globalization.getRegion(), this.$i18n.locale, options, null, this.isInchSearch ? [INCH_FILTER.customQuery, SHOW_INCH_VALUES_FILTER.customQuery] : null, PROPERTY_SCOPE_NAMES.SEARCH_OVERLAY_NEW, [], designFilter)
            res = {...res, customerSuggestions: customerRes?.searchResults}
          } else {
            res = await this.getProductsBySearchTerm(this.searchTerm, [NON_ATTRIBUTE_FILTERS.PRIMARYL2CATEGORY], this.$globalization.getRegion(), this.$i18n.locale, options, null, this.isInchSearch ? [INCH_FILTER.customQuery, SHOW_INCH_VALUES_FILTER.customQuery] : null, PROPERTY_SCOPE_NAMES.SEARCH_OVERLAY, [], designFilter)
          }
          if (this.searchTime !== time) {
            // discard results if they're not for most recent search
            return
          }
          this.searchTermResult = res
          this.isAnySearch = res?.isAnySearch ?? false
          this.$gtmReset()
          this.$gtm.trackEvent({
            event: 'search',
            category: 'product_search',
            action: 'standard_search',
            ecommerce: {
              'search_term': this.searchTerm,
              'search_count': this.searchTermResult?.count}
          })
          this.$appInsights?.trackEvent({
            name: 'Search', properties: {
              searchServiceName: this.searchTermResult?.azureSearchTelemetry?.searchServiceName,
              searchId: this.searchTermResult?.azureSearchTelemetry?.searchId,
              indexName: this.searchTermResult?.azureSearchTelemetry?.indexName,
              queryTerms: this.searchTerm,
              resultCount: this.searchTermResult?.count,
              scoringProfile: this.searchTermResult?.azureSearchTelemetry?.scoringProfile === null ? '' : this.products?.azureSearchTelemetry?.scoringProfile
            }
          })
          if (this.searchTermResult) {
            if (this.newSearch) {
              this.primaryl2Categories = this.searchTermResult.facets.primaryL2Category.slice(0, SEARCH.MAX_FACET_DESIGNS).map(d => ({categoryName: d.value, hitCount: d.count}))
            } else {
              this.primaryl2Categories = this.searchTermResult.facets.PrimaryL2Category.slice(0, SEARCH.MAX_FACET_DESIGNS).map(d => ({categoryName: d.value, hitCount: d.count}))
            }
            this.l2Categories = await this.getCategoriesByTechnicalNames(this.primaryl2Categories.map(d => d.categoryName))
          }
        }
        else {
          if (!this.newSearch) {
            this.showOverlay = false
          }
        }
        this.isLoading = false
      }, 300)
    },
    login() {
      navigateTo({path: '/auth/login'})
    },
    openBuyBox(product) {
      this.product = product
      this.openCartModal = true
    },
    async openBuyBoxSku(sku) {
      this.product = this.newSearch ?
        await this.getProductBySkuNew(sku, this.$globalization.getRegion(), this.$i18n.locale, this.showInchValues, null, PROPERTY_SCOPE_NAMES.PRODUCT_PAGE_NEW) :
        await this.getProductBySku(sku, this.$globalization.getRegion(), this.$i18n.locale, this.showInchValues, null, PROPERTY_SCOPE_NAMES.PRODUCT_PAGE)
      if (this.product) {
        this.openCartModal = true
        this.showOverlay = false
      } else {
        this.$toast.error(this.$tv('productNotAccessible', 'product'))
      }
    },
    onProductSuccessfullyAdded() {
      this.openCartModal = false
    },
    onClickOutside() {
      if (this.showOverlay) {
        this.showOverlay = false
      }
    },
    changeUnit(val) {
      this.searchTerm = val
      if (this.searchTerm) {
        this.getOverlay(this.searchTerm)
      }
      this.setShowInchValues(!this.showInchValues)
    },
    clickItem(sku, rank) {
      this.$appInsights?.trackEvent({
        name: 'Click', properties: {
          searchServiceName: this.searchTermResult?.azureSearchTelemetry?.searchServiceName,
          searchId: this.searchTermResult?.azureSearchTelemetry?.searchId,
          clickedDocId: sku,
          rank: rank
        }
      })
    },
    resetSearch() {
      this.searchTermResult = {}
      this.primaryl2categories = []
      this.l2Categories = []
      this.searchTerm = ''
      this.isAnySearch = false
    },
    handleCategoryTooltip() {
      clearTimeout(this.tooltipTimer)
      this.tooltipTimer = setTimeout(() => this.showCategoryToolTip = !this.showCategoryToolTip, 100)
    }
  },
  watch: {
    'configurations': {
      immediate: true,
      handler(val) {
        if (this.socialIntentsLoadedOnce) {
          if (!this.hasShowSocialIntentsChat) {
            this.$hideTabSocialIntents()
          } else {
            this.$showTabSocialIntents()
          }
        }
        if (val.length > 0 && this.loggedIn && this.hasShowSocialIntentsChat && !this.socialIntentsLoadedOnce) {
          this.$loadSocialIntents()
          this.socialIntentsLoadedOnce = true
        }
      }
    },
    'items.length': {
      handler() {
        this.pulseCart = true
        setTimeout(() => this.pulseCart = false, 1200)
      }
    },
    '$i18n.locale': {
      handler() {
        if (this.selectedCategory?.id !== 'all') {
          this.selectedCategory = {text: this.$tv(this.selectedCategory.id, 'categories'), id: this.selectedCategory.id}
        } else {
          this.selectedCategory = {text: this.$tv(this.selectedCategory.id, 'search'), id: this.selectedCategory.id}
        }
      }
    },
    currentCustomerUuid: {
      immediate: true,
      handler() {
        this.resetSearch()
      }
    },
    '$route.path': {
      handler() {
        this.showCategoryToolTip = false
      }
    }
  },
  computed: {
    menuOpen() {
      return this.menuCatOpenHover || this.menuSelectorsOpenHover
    },
    menuSections() {
      return this.menuCatOpenHover ? this.menuCategories : this.menuSelectors
    },
    mobileMenuSections() {
      let sections = []

      sections.push({
        label: this.$tv('products', 'menu'),
        localRoute: '',
        externalUrl: '',
        Subsections: this.menuCategories
      })

      if (this.menuSelectors.length > 0) {
        sections.push(
          this.menuSelectors[0]
        )
      }

      sections = sections.concat(this.services)

      if (this.hasRight(USER_RIGHTS_MAPPING.ORDER_HISTORY_VIEW) || this.hasRight(USER_RIGHTS_MAPPING.QUOTE_HISTORY_VIEW)) {
        sections.push({
          label: this.$tv('order-history', 'product'),
          localRoute: 'orderHistory',
          externalUrl: ''
        })
      }

      return sections
    },
    availableCompanies() {
      return this.availableCompaniesStore?.length
    },
    hasShowSocialIntentsChat() {
      const showSocialIntentsChat = this.configurations?.find(config => config.key === CONFIGURATION_KEYS.SHOW_SOCIAL_INTENTS_CHAT)
      return showSocialIntentsChat?.value === 'True'
    },
    buyboxClass() {
      return 'popup__buy-box-new'
    },
    allowedRegions() {
      return (useRuntimeConfig().public.CCRS_ALLOWED_REGIONS || 'EU').split(',').some(item => item.toLowerCase() === this.$globalization.getRegionFromURL()?.toLowerCase())
    },
    services() {
      let services = [
        {
          label: this.$tv('services.DistributorSearch', 'header'),
          localRoute: '/distributor-search',
          externalUrl: ''
        }
      ]
      if (this.allowedRegions) {
        services.push({
          label: this.$tv('title', 'ccrs'),
          localRoute: 'cross-reference'
        })
      }
      services.push({
        label: this.$tv('title', 'chemicalResistanceGuide'),
        localRoute: 'chemical-resistance-guide'
      })
      if (this.loggedIn && this.currentCustomerSettings?.some(s => s.translationKey === CUSTOMER_SETTING_KEYS.XpressAllowed && (s.customerSettingValue === '1'))) {
        services.push({
          label: this.$tv('services.xpressMenuLink', 'header'),
          localRoute: 'xpress'
        })
      }
      return services
    },
    menuSelectors() {
      let selectors = []
      if (useRuntimeConfig().public.ORINGCONFIGURATOR_URL) {
        selectors.push({
          label: this.$tv('services.ORingConfigurator', 'header'),
          localRoute: '',
          externalUrl: `${useRuntimeConfig().public.ORINGCONFIGURATOR_URL}?lang=${this.$i18n.locale}`
        })
      }
      if (this.$globalization.getRegion() !== REGION_KEYS.NA || useRuntimeConfig().public.HYDRAS_LINK_DISABLED_NA !== '1') {
        selectors.push({
          label: this.$tv('services.hydraulicSelector', 'header'),
          localRoute: '/hydraulic-selector',
          externalUrl: ''
        })
      }
      if (this.$globalization.getRegion() !== REGION_KEYS.NA || useRuntimeConfig().public.ACCUS_LINK_DISABLED_NA !== '1') {
        selectors.push({
          label: this.$tv('services.accumulatorSelector', 'header'),
          localRoute: '/accumulator-selector',
          externalUrl: ''
        })
      }
      if (useRuntimeConfig().public.SIMMERINGSELECTOR_URL) {
        selectors.push({
          label: this.$tv('services.SimmerringSelector', 'header'),
          localRoute: '',
          externalUrl: `${useRuntimeConfig().public.SIMMERINGSELECTOR_URL}?language=${this.$i18n.locale}`
        })
      }

      let services = [{
        label: this.$tv('selectorsMenuLabel', 'header'),
        localRoute: '',
        externalUrl: '',
        Subsections: selectors
      }]
      return services
    },
    hasCartViewPermission() {
      return this.hasRight(this.USER_RIGHTS_MAPPING.CART_VIEW)
    },
    listLinks() {
      const listLinks = []

      if (this.hasRight(USER_RIGHTS_MAPPING.ORDER_HISTORY_VIEW) || this.hasRight(USER_RIGHTS_MAPPING.QUOTE_HISTORY_VIEW)) {
        listLinks.push({label: this.$tv('order-history', 'product'), url: 'orderHistory'})
      }
      return listLinks
    },
    isInchSearch() {
      return this.showInchValues
    },
    treeDataItems() {
      return processTreeViewItems(this.categoriesTree, {
        select: this.select,
        check: this.check,
        expand: this.expand
      })
    },
    filteredCategories() {
      let categoriesInRegion = this.categories.map(c => ({...c, Subcategories: c.Subcategories?.filter(sc => this.categories.some(cc => cc.TechnicalName === sc.TechnicalName) && sc.brands.filter(b => !this.excludedBrands.includes(b.Code.toLowerCase())).length > 0).sort(defaultCategoriesComparer)}))
      return categoriesInRegion.filter(c => c.IsTopCategory && c.brands.filter(b => !this.excludedBrands.includes(b.Code.toLowerCase())).length > 0)
    },
    menuCategories() {
      let categories = this.categories.filter(c => c.IsTopCategory && c.IsVisibleInMenu).sort(defaultCategoriesComparer).map(c => ({...c, Subcategories: c.Subcategories.sort(defaultCategoriesComparer)}))
      return categories.map(c => ({label: this.$tv(c.TechnicalName, 'categories'), localRoute: `${CATEGORIES.RootCategoriesUrl}/${getUrlFromTechnicalName(c.TechnicalName, this.$i18n.locale)}`, Subsections: c.Subcategories.filter(c => c.IsVisibleInMenu && c.regions.some(r => r.RegionShortName === this.$globalization.getRegion())).map(sc => ({label: this.$tv(sc.TechnicalName, 'categories'), localRoute: `${CATEGORIES.RootCategoriesUrl}/${getUrlFromTechnicalName(c.TechnicalName, this.$i18n.locale)}/${getUrlFromTechnicalName(sc.TechnicalName, this.$i18n.locale)}`, brands: sc.brands})), brands: c.brands}))
    },
    categoriesTree() {
      let categories = this.filteredCategories
      let categoriesTree = []

      categoriesTree.push({
        text: this.$tv('all_long', 'search'),
        id: 'all',
        items: []
      })

      categories.forEach(c => {
        let designs = []
        let category = {
          text: this.$tv(c.TechnicalName, 'categories'),
          id: c.TechnicalName,
          isTopCategory: c.IsTopCategory,
          searchFilter: c.Subcategories.map(sc => sc.TechnicalName),
          items: []
        }

        c.Subcategories.forEach(sc => {
          let catDesigns = sc?.Subcategories?.filter(ssc => ssc?.IsProductDesign).map(ssc => ssc?.TechnicalName)
          catDesigns.forEach(d => {
            if (!designs.includes(d)) {
              designs.push(d)
            }
          })
          category.items.push({
            text: this.$tv(sc.TechnicalName, 'categories'),
            id: sc.Subcategories?.filter(ssc => ssc?.IsProductDesign),
            isTopCategory: false,
            searchFilter: catDesigns,
            items: []
          })
        })
        category.searchFilter = designs
        categoriesTree.push(category)
      })

      return categoriesTree
    },
    onlyResultDirectLink() {
      return this.searchTermResult?.products && this.searchTermResult?.count === 1 ?
        this.localePath('/categories/' + getUrlFromTechnicalName(this.searchTermResult?.products[0].productDesign, this.$i18n.locale) +
        '/products' + '/' + this.searchTermResult?.products[0]?.sku, this.$i18n.locale) : null
    }
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }
})
</script>

<style lang="scss">
.nav__main__shop__icon {
  .k-i-loading {
    font-size: .7rem !important;
  }
}

.nav__main__logo {
  max-width: 18%;
  width: 100%;
  min-width: 11.25rem;

  svg,
  img {
    width: 100%;
    height: auto;
  }
}

.nav {
  &__main__shop__cart__link {
    display: flex;
  }
}

.megamenu {
  background-color: $color__white;
  padding: 1.25rem 3.75rem 1.25rem 3rem;
  border-bottom: .125rem solid $color__fst__light__gray;

  @include breakpoint-down($md) {
    display: none;
  }
}

.mobile-menu-wrapper {
  display: none;

  &:has(.mobile-menu.open) {
    display: block;
  }
}

.fixed-header {
  z-index: 12;
  position: fixed;
  top: 0;
  max-width: 80rem;
  background-color: $color__white;
}

.nav__waiting__indicator {
  .skeleton {
    width: 100%;
    margin: .25rem 1rem;
    display: flex;
    align-items: center;

    .skeleton__arrow {
      height: .25rem;
      width: 1rem;
      margin-right: 0;
    }

    .skeleton__text {
      height: 1rem;
      flex-grow: 1;
      margin-right: 1rem;
    }
  }
}

.header-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.header-row:nth-child(2) {
  align-items: center;
  justify-content: flex-start;

  &.scrolled {
    max-height: 0;
    overflow: hidden;
  }
}

.nav__main__categories {
  display: flex;
  flex-wrap: nowrap;

  a {
    font-weight: $base-text-font-weight-semi-bold;
    color: $color__fst__primary;
    text-decoration: none;
    margin-right: 2rem;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;

    .k-icon {
      margin-left: .25rem;
    }

    &.mega-menu_cat::after,
    &.mega-menu_sel::after {
      position: absolute;
      content: '';
      bottom: 0;
      width: 100%;
      right: 0;
      display: inline-block;
      height: .125rem;
      background-color: $color__primary;
    }
  }
}

.nav__main__container {
  .k-button {
    min-width: 11.6rem;
  }

  > div {
    min-height: 2.5rem;
    display: flex;
    flex-direction: row;
    place-content: center center;
  }

  &.nav__main__shop > div {
    min-height: 2.75rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    > a {
      position: relative;
      top: .1rem;
    }
  }

  &.nav__main__logos {
    > div {
      min-height: 2.75rem;
      display: flex;
      flex-direction: column;
      align-content: center;
    }

    @include breakpoint-down($lg) {
      display: none;
    }

    img {
      max-width: 15rem;
    }

    .svg__w-easy {
      height: 2.125rem;
    }
  }
}

@include breakpoint-up($md) {
  .desktop-move-notifications .popup__wrapper {
    max-width: 30rem;
    left: 23.4rem;
    position: relative;
    top: 1.2rem;
  }

  .nav.nav .nav__waiting__indicator {
    display: none;
  }
}

@include breakpoint-down($md) {
  .nav.nav .nav__waiting__indicator {
    min-height: 2.188rem;
    height: 2.188rem;
  }
}

@include breakpoint-between($md, $xl) {
  .desktop-move-notifications .popup__wrapper {
    left: unset;
  }
}

#category-tooltip {
  &.tool-tip__wrapper {
    background-color: $color__white;
    justify-content: start;
    width: auto;

    .k-treeview {
      overflow: hidden;
    }

    &.bottom {
      top: calc(100%);
    }

    .k-focus {
      box-shadow: none;
    }

    .k-treeview-leaf:hover {
      cursor: pointer;
    }
  }

  li[aria-selected='true'] {
    .k-treeview-toggle {
      background-color: $color__primary;

      .k-icon::before {
        background-color: $color__primary;
        color: $color__white;
      }
    }
  }

  .k-treeview-item {
    padding-left: 0;

    > div {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
    }

    .k-treeview-leaf {
      width: 100%;
      padding-right: 2.5rem;
    }

    .k-treeview-toggle {
      margin-left: -1.5rem;
      position: relative;
      min-height: 1.75rem;
      min-width: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .k-icon {
      position: relative;
      right: 0;
      z-index: 201;
      min-height: 1.5rem;

      &::before {
        color: $color__primary;
        min-width: 1.5rem;
        position: relative;
        left: .2rem;
      }
    }

    .k-i-caret-alt-right {
      &::before {
        content: '\e014';
      }
    }

    .k-i-caret-alt-down {
      &::before {
        content: '\e015';
      }
    }
  }

  .k-treeview ul li ul li {
    .k-treeview-leaf {
      padding-left: 1.5rem;
    }
  }
}

.nav .nav__main__service_menu {
  a {
    font-weight: $base-text-font-weight-semi-bold;
    text-decoration: none;
  }

  a:hover {
    color: $color__primary;
  }
}

.logged-out .nav__main__container.nav__main__logos {
  margin-right: 0;
}
</style>
